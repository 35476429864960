import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        themes: {
            dark: {
                primary: '#C16D87',
            },
            light: {
                primary: '#C16D87',
            },
        },
    },
})
