import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../pages/Home.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { display: false, redirectAuthUser: true }
  },
  {
    path: '/thank-you',
    name: 'ThankYou',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../pages/ThankYou.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/payment-thank-you',
    name: 'InvoicePaymentThankYou',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../pages/PaymentStatus.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/download',
    name: 'AppDownload',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../pages/AppDownload.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/checkout-payment-thank-you',
    name: 'CheckoutPaymentThankYou',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'../pages/PaymentStatus.vue'),
    meta: { requiresAuth: false }
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
